<template>
  <v-container fluid class="text-center">
    <v-card class="elevation-5 mt-5 mx-5">
      <v-toolbar dense dark color="secondary" elevation="2">
        <v-toolbar-title> Zonas </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn text small @click="addNewZone">
          Agregar
          <v-icon right>mdi-plus</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-title>
        <v-btn small :loading="loading" elevation="0" color="secondary" outlined class="mx-2" @click="refresh()">
          Actualizar
          <v-icon right>mdi-refresh</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar"
          class="mx-2"
          single-line
          hide-details
          maxlength="150"
        ></v-text-field>
      </v-card-title>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="items"
        :loading="loading"
        :search="search"
        :footer-props="{ 'items-per-page-text': 'Elementos por página', 'items-per-page-options': [20, 50, -1] }"
        :items-per-page="10"
        item-key="id"
        class="elevation-1"
        dense
      >
        <v-progress-linear slot="progress" color="secondary" indeterminate></v-progress-linear>
        <template v-slot:[`item.plants`]="{ item }"> {{ item.plantas?.length || 0 }} </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-row>
            <div v-for="(action, index) in actions(item)" :key="index">
              <v-tooltip
                :top="action.position === 'top'"
                :left="action.position === 'left'"
                :right="action.position === 'right'"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    :size="22"
                    :color="action.color"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    :disabled="loading"
                    @click="action.onClick"
                  >
                    {{ action.icon }}
                  </v-icon>
                </template>
                <span>{{ action.tooltip }}</span>
              </v-tooltip>
            </div>
          </v-row>
        </template>

        <template v-slot:[`footer.page-text`]="props">
          Página {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }}
        </template>
        <template v-slot:no-data> No hay información disponible </template>
        <template v-slot:no-results> No se obtuvieron resultados </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="dialogs.new" persistent max-width="600px">
      <v-card>
        <v-toolbar dense dark color="secondary" elevation="2">
          <v-toolbar-title>Nueva zona</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" @click="cancel()">
            Cerrar
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn text small :loading="loading" @click="save()">
            Guardar
            <v-icon right>mdi-check</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form ref="formNew" lazy-va2lidation>
              <v-row class="mt-2">
                <v-col cols="12">
                  <v-text-field
                    v-model="forms.new.nombre"
                    label="Nombre"
                    :rules="rules.nombre"
                    :disabled="loading"
                    dense
                    outlined
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-title> Plantas </v-card-title>
        <v-card-text v-if="!availablePlants[0]"> No hay plantas disponibles </v-card-text>
        <v-card-text>
          <v-checkbox
            v-for="planta in availablePlants"
            :key="planta.idplanta"
            v-model="selectedPlants"
            :label="planta.nombre"
            :value="planta.idplanta"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.update" persistent max-width="600px">
      <v-card>
        <v-toolbar dense dark color="secondary" elevation="2">
          <v-toolbar-title>Actualizar zona</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" @click="cancel()">
            Cerrar
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn text small :loading="loading" @click="update()">
            Actualizar
            <v-icon right>mdi-check</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form ref="formUpdate" lazy-validation>
              <v-row class="mt-2">
                <v-col cols="12">
                  <v-text-field
                    v-model="forms.update.nombre"
                    label="Nombre"
                    :rules="rules.nombre"
                    :disabled="loading"
                    dense
                    outlined
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-title> Plantas </v-card-title>
        <v-card-text v-if="!filteredPlants[0]"> No hay plantas disponibles </v-card-text>
        <v-card-text>
          <v-checkbox
            v-for="planta in filteredPlants"
            :key="planta.idplanta"
            v-model="selectedPlants"
            :label="planta.nombre"
            :value="planta.idplanta"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.companies" persistent max-width="600px">
      <v-card>
        <v-toolbar dense dark color="secondary" elevation="2">
          <v-toolbar-title>Empresas asignadas</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" @click="cancel()">
            Cerrar
            <v-icon right>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form ref="formCompanies" lazy-validation>
              <v-row class="mt-2">
                <template v-for="(item, idx) in forms.update.empresas">
                  <v-col cols="12" :key="idx">
                    <v-text-field :value="item" readonly dense outlined required></v-text-field>
                  </v-col>
                </template>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.remove" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Aviso</v-card-title>
        <v-card-text>¿Realmente desea eliminar la zona?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" color="blue-grey" @click="cancel()"> Cancelar </v-btn>
          <v-btn text small :loading="loading" color="error" @click="remove()"> Eliminar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="success" color="success" :timeout="2500" bottom right>
      {{ msgSuccess }}
    </v-snackbar>
    <v-snackbar v-model="error" color="error" :timeout="2500" bottom right>
      {{ msgError }}
    </v-snackbar>
  </v-container>
</template>

<script>
import services from '@/utils/services'
import { api } from '@/utils'

export default {
  name: 'zone',
  data: () => ({
    loading: false,
    success: false,
    msgSuccess: '',
    error: false,
    msgError: '',
    search: '',
    headers: [
      { text: 'Nombre', align: 'left', sortable: false, value: 'nombre' },
      { text: 'Número de plantas', align: 'left', sortable: false, value: 'plants' },
      { text: 'Acciones', align: 'left', sortable: false, value: 'actions' }
    ],
    items: [],
    selected: [],
    deleted: null,
    forms: {
      new: {
        nombre: ''
      },
      update: {
        idzona: '',
        nombre: ''
      }
    },
    rules: {
      nombre: [
        (v) => !!v || 'El campo es requerido',
        (v) => (v && v.length <= 50) || 'El campo excede la longitud máxima'
      ]
    },
    dialogs: {
      new: false,
      update: false,
      companies: false,
      remove: false
    },
    plants: [],
    filteredPlants: [],
    availablePlants: [],
    selectedPlants: []
  }),
  mounted() {
    this.refresh()
    this.getAllPlants()
  },
  methods: {
    addNewZone() {
      this.selectedPlants = []
      this.dialogs.new = true
    },
    async getAvailablePlants() {
      const res = await api('get', 'getAvailablePlants')
      this.availablePlants = res?.data || []
    },
    actions(item) {
      return [
        {
          icon: 'mdi-pencil',
          color: 'primary',
          position: 'left',
          tooltip: 'Editar',
          onClick: () => this.view(item)
        },
        {
          icon: 'mdi-domain',
          color: 'primary',
          position: 'top',
          tooltip: 'Empresas asignadas',
          onClick: () => this.view(item, 'companies')
        },
        {
          icon: 'mdi-delete',
          color: 'error',
          position: 'right',
          tooltip: 'Eliminar',
          onClick: () => this.remove(item.idzona)
        }
      ]
    },
    async getAllPlants() {
      const res = await api('get', 'getAllPlants')
      this.plants = res?.data || []
    },
    refresh() {
      this.getAllPlants()
      this.getAvailablePlants()
      this.loading = true
      this.axios
        .get(services.routes.zone)
        .then((response) => {
          this.items = response.data.data
        })
        .catch((error) => {})
        .finally(() => {
          this.loading = false
        })
    },
    cancel() {
      const { new: isNew, update: isUpdate, remove: isRemove } = this.dialogs

      if (isNew || isUpdate) {
        const formRef = isNew ? 'formNew' : 'formUpdate'
        const formData = isNew ? this.forms.new : this.forms.update

        this.$refs[formRef].reset()

        Object.keys(formData).forEach((key) => {
          formData[key] = ''
        })

        this.dialogs[isNew ? 'new' : 'update'] = false
      }

      if (isRemove) {
        this.dialogs.remove = false
      }

      this.dialogs.companies = false
    },
    save() {
      if (this.$refs.formNew.validate()) {
        this.loading = true
        let data = JSON.parse(JSON.stringify({ ...this.forms.new, plantIds: this.selectedPlants }))
        this.axios
          .post(services.routes.zone, { data })
          .then((response) => {
            this.cancel()
            this.refresh()
            this.success = true
            this.msgSuccess = 'Zona creada exitosamente'
          })
          .catch((error) => {
            this.error = true
            this.msgError = error.response.data.message || 'Servicio no disponible'
          })
          .finally(() => {
            this.loading = false
          })
      } else {
        this.error = true
        this.msgError = 'La información es incorrecta'
      }
    },
    view(data, type) {
      const { idzona, nombre, plantas, empresas_zonas } = data

      // Actualiza los formularios
      this.forms.update = { idzona, nombre }

      // Selecciona las plantas
      this.selectedPlants = plantas.map((planta) => planta.idplanta)

      // Filtra las plantas disponibles
      const plantsOfThisItem = this.plants.filter((planta) =>
        plantas.some((planta2) => planta.idplanta === planta2.idplanta)
      )
      this.filteredPlants = [...this.availablePlants, ...plantsOfThisItem]

      // Maneja el tipo de diálogo
      if (type === 'companies') {
        this.forms.update.empresas = empresas_zonas.map((ez) => ez.empresa.nombre)
        this.dialogs.companies = true
      } else {
        this.dialogs.update = true
      }
    },
    update() {
      if (this.$refs.formUpdate.validate()) {
        this.loading = true
        let data = JSON.parse(JSON.stringify({ ...this.forms.update, plantIds: this.selectedPlants }))
        this.axios
          .put(services.routes.zone, { data })
          .then((response) => {
            this.cancel()
            this.refresh()
            this.success = true
            this.msgSuccess = 'Zona actualizada exitosamente'
          })
          .catch((error) => {
            this.error = true
            this.msgError = error.response.data.message || 'Servicio no disponible'
          })
          .finally(() => {
            this.loading = false
          })
      } else {
        this.error = true
        this.msgError = 'La información es incorrecta'
      }
    },
    remove(id) {
      if (!id) {
        this.loading = true
        this.axios
          .delete(services.routes.zone, { data: { data: { idzona: this.deleted } } })
          .then((response) => {
            this.cancel()
            this.refresh()
            this.success = true
            this.msgSuccess = 'Zona eliminada exitosamente'
          })
          .catch((error) => {
            this.error = true
            this.msgError = error.response.data.message || 'Servicio no disponible'
          })
          .finally(() => {
            this.loading = false
          })
      } else {
        this.deleted = id
        this.dialogs.remove = true
      }
    }
  }
}
</script>
